<template>
  <div class="page1">
    <EForm :formColumns="formColumns"
           :rowSize="4"
           :optionsBtn="true"
           :actionBtn="false"
           :formData="searchForm"
           ref="form"
           @onSearch="onSearch"
           :searchFlag="true"
           :exportShow="false"
           @getChange="changeSelect">
    </EForm>
    <div class="mt20 fs15 flex flex-space-between">
            <span>
                符合条件记录：共{{total.feeNum}}单，{{total.totalAmount}}元 插卡式电表电费：{{total.electricPriceTotal}}元    插卡式电表供电服务管理费：{{total.electricServicePriceTotal}}元
            </span>
      <el-button type="primary" size="small" @click="dialogShow">录入费用</el-button>
    </div>
    <ETable :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.current"
            :page_size="searchForm.size">
      <el-table-column
        fixed="right"
        label="操作"
        width="180px"
      >
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="editDialog(scope.row)"
                     :disabled="scope.row.status==1"
            >
              修改
            </EButton>
            <EButton type="text" @click="remove(scope.row.orderId)"
                     :disabled="scope.row.status==1"
            >
              删除
            </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>
    <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
             width="800px"
             @handleClick="saveData"
             :disabled="saveDisabled">
      <el-form inline size="small">
        <el-form-item label="摊位号">
          <el-select v-model="boothId" filterable placeholder="请选择" @change="getContractByBoothId">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form inline label-width="100px" label-position="left" :model="form" v-if="boothId">
        <el-row>
          <el-col :span="8">
            <el-form-item label="区域">

              <div>{{ form.areaName || '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="位置">

              <div>{{ form.placeName|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摊位">

              <div>{{ form.boothCode|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号码">

              <div>{{ form.idCard|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同开始日期">
              <div>{{ form.inDate|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人名称">
              <div>{{ form.signPerson|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <div>{{ form.phone|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摊位面积">
              <div>{{ form.areas|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同类别">
              <div>{{ form.contractTypeCn|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同到期日期">
              <div>{{ form.expireDate|| '-'}}</div>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div class="title">
        {{ form.takeReturnProjectName}}
      </div>
      <el-form inline label-width="130px" label-position="left" :rules="formRules" :model="form" ref="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="电价">
              {{electricUnitPrice}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务费单价">
              {{electricPrice}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合计">
              {{electricTotalPrice}}
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="购电金额" prop="totalAmount">
              <el-input class="number" type="number" v-model.number="form.totalAmount" @input="calcFee"
                        style="width: 180px;"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="费用开始时间">
              <el-date-picker
                v-model="form.feeBeginDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsStart"
                style="width: 180px;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="费用结束时间">
              <el-date-picker
                v-model="form.feeEndDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsEnd"
                style="width: 180px;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="购电度数">
              <el-input class="number" type="number" v-model="form.degrees" @input="calcAmount"
                        style="width: 180px;"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="是否需要财务确认">
              <el-radio-group v-model="form.isConfirm">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" :sm="12">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="form.remark" style="width: 180px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </EDialog>
    <EDialog :dialogVisible="editDialogVisible" :title="title" @handleClose="cancelDialog"
             width="800px"
             @handleClick="expenseEntryUpdatee"
             :disabled="saveDisabled">
      <div class="mb20">
        <span class="pr20">摊位号<span class="pl10">{{form.boothCode}}</span></span>
        <span class="pr20">费用名称<span class="pl10">{{form.takeReturnProjectName}}</span></span>
      </div>
      <el-form inline label-width="100px" label-position="left" :model="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="区域">
              <div>{{ form.areaName || '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="位置">

              <div>{{ form.placeName|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摊位">

              <div>{{ form.boothCode|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号码">

              <div>{{ form.idCard|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同开始日期">
              <div>{{ form.inDate|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人名称">
              <div>{{ form.signPerson|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <div>{{ form.phone|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="摊位面积">
              <div>{{ form.areaName|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同类别">
              <div>{{ form.contractTypeCn|| '-'}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同到期日期">
              <div>{{ form.expireDate|| '-'}}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <div class="title">
          {{form.takeReturnProjectName}}
        </div>
        <div class="mb20">
          <span class="pr20">电价<span class="pl10">{{electricUnitPrice}}</span></span>
          <span class="pr20">服务费单价<span class="pl10">{{electricPrice}}</span></span>
          <span class="pr20">合计<span class="pl10">{{sum}}</span></span>
        </div>
        <el-form inline label-width="140px" label-position="left">
          <el-row>
            <el-col :span="12" :sm="12">
              <el-form-item label="购电金额">
                <el-input class="number" type="number" v-model.number="form.totalAmount" @input="calcFee"/>
              </el-form-item>
            </el-col>
            <el-col :span="12" :sm="12">
              <el-form-item label="费用开始时间">
                <el-date-picker
                  v-model="form.feeBeginDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" :sm="12">
              <el-form-item label="费用结束时间">
                <el-date-picker
                  v-model="form.feeEndDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" :sm="12">
              <el-form-item label="购电度数">
                <el-input class="number" type="number" v-model="form.degrees" @input="calcAmount"/>
              </el-form-item>
            </el-col>
            <el-col :span="12" :sm="12">
              <el-form-item label="是否需要财务确认">
                <el-radio-group v-model="form.isConfirm">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" :sm="12">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="form.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </EDialog>
  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import costEdit from './costEdit'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import * as math from "mathjs";
  import {dateFormat} from '@/assets/js/common'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum',
            width:150
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName',
            width:150
          },
          {
            label: '购电金额',
            prop: 'totalAmount'
          },
          {
            label: '购电度数',
            prop: 'electricNum'
          },
          {
            label: '插卡式电表电费',
            prop: 'electricMeterPrice'
          },
          {
            label: '插卡式电表供电服务管理费',
            prop: 'electricMeterServicePrice'
          },
          {
            label: '录入人',
            prop: 'createUserCn'
          },
          {
            label: '录入时间',
            prop: 'createTime'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '财务确认',
            prop: 'confirmStatusCn'
          },
          {
            label: '收费状态',
            prop: 'statusCn'
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '财务确认',
            type: 'select',
            property: 'confirmStatus',
            show: true,
            options: [
              {
                label: '全部',
                value: ''
              },
              {
                label: '未确认',
                value: 1
              },
              {
                label: '已确认',
                value: 2
              },
              {
                label: '不确认',
                value: 3
              },
              {
                label: '已退回',
                value: 4
              },
            ]
          },
          {
            title: '录入开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '收费状态',
            type: 'select',
            property: 'status',
            show: true,
            options: [
              {
                label: '全部',
                value: ''
              },
              {
                label: '未收费',
                value: 0
              },
              {
                label: '已收费',
                value: 1
              },
              {
                label: '已取消',
                value: 2
              },
            ]
          },
          {
            title: '录入结束时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          createTimeStart: null,
          areaId: null,
          createTimeEnd: null,
          placeId: null,
          createUser: null,
          confirmStatus: null,
          status: null,
          feeType: 3,//3:电卡充值 4:其他收费项 5:抄电表费
          current: 1,
          size: 10,
        },
        count: null,
        total: {
          feeNum: 0,
          totalAmount: 0,
          electricServicePriceTotal: 0,
          electricPriceTotal: 0
        },
        dialogVisible: false,//2021-2-5新增的费用录入的需求
        boothId: '',
        formRules: {
          totalAmount: vxRule(true, '', "blur", "金额不能为空"),
        },
        options1: [],//摊位号下拉
        title: '', //费用名称
        elePriceList: [], //电费信息
        form: { //费用录入传值
          areaName: '',
          placeName: '',
          boothCode: '',
          idCard: '',
          inDate: '',
          signPerson: '',
          phone: '',
          areas: '',
          contractTypeCn: '',
          expireDate: '',
          areaId: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: 3,
          isConfirm: 2,
          orderId: '',
          placeId: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: '',

        },
        pickerOptionsStart: {
          disabledDate: (time) => {
            let feeEndDate = this.form.feeEndDate
            if (feeEndDate)
              return time.getTime() > new Date(feeEndDate).getTime()
          }
        },
        pickerOptionsEnd: {
          disabledDate: (time) => {
            let feeBeginDate = this.form.feeBeginDate
            if (feeBeginDate)
              return time.getTime() < new Date(feeBeginDate).getTime() - 86400000
          }
        },
        editDialogVisible: false
      }
    },
    computed: {

      sum() {
        return math.chain(this.electricPrice).add(this.electricUnitPrice).done()
      },
      electricTotalPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricTotalPrice) || 0
      },
      electricPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricPrice) || 0
      },
      electricUnitPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricUnitPrice) || 0
      }
    },
    watch: {},
    created() {
      this.getData()
      this.getAreaLabel()
      this.getElePriceList()
      this.cmboothinformationLabel()
    },
    components: {ETable, EDialog, EButton, EForm, costEdit},
    beforeMount() {

    },
    methods: {
      async expenseEntryUpdatee() {
        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntryUpdatee(this.form)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      editDialog(data) {
        this.editDialogVisible = true
        this.expenseEntryDetail(data.orderId)
      },
      //费用录入详情
      async expenseEntryDetail(orderId) {
        let res = await Http.expenseEntryDetail({id: orderId})
        if (res.code == 200) {
          this.form = res.data
          this.form.degrees = res.data.electricNum
          this.form.isConfirm = Number(res.data.isConfirm)
        }
      },
      //摊位信息-摊位号下拉
      async cmboothinformationLabel() {
        //已租和未租的value值0和1
        let res = await Http.cmboothinformationLabel({isRent: 1, status: 1})
        if (res.code == 200) {
          this.options1 = res.data
        }
      },
      //费用录入详情
      async getContractByBoothId() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        let res = await Http.getContractByBoothId({id: this.boothId})
        let fields = ['areaId', 'areaName', 'placeName', 'boothCode', 'idCard', 'inDate', 'signPerson', 'phone', 'areas', 'contractTypeCn', 'expireDate', 'tenantId', 'tenantName', 'placeId', 'contractId']
        if (res.code == 200) {
          // 根据摊位号获得摊位合同详情
          let data = res.data
          fields.forEach(field => {
            this.form[field] = data[field]
          })
        } else {
          fields.forEach(field => {
            this.form[field] = ''
          })
        }
      },
      saveData() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        // if (!this.form.takeReturnProjectId) {
        //   this.$message.error('请选择费用名称');
        //   return
        // }
        this.$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid) {
            this.expenseEntry()
          } else {
            return false;
          }
        })

      },
      async expenseEntry() {
        console.log(this.sum)
        if (this.sum == 0) {
          this.$message.error('请先去电价设置里配置电价');
          return
        }
        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntry(this.form)
        if (res.code == 200) {
          this.setDisabled(false)
          this.$message.success(res.msg);
          this.getData()
          this.cancelDialog()
        }
      },
      //由购电金额得到购电度数
      calcFee(totalAmount) {
        let tmpDegrees = ''
        if (totalAmount) {
          tmpDegrees = math.round(math.evaluate(`${totalAmount} / ${this.electricTotalPrice}`), 2)
        }
        this.form.degrees = tmpDegrees
      },
      //由购电度数得到购电金额
      calcAmount(degrees) {
        let tmpAmount = ''
        if (degrees) {
          tmpAmount = math.round(math.evaluate(`${degrees} * ${this.electricTotalPrice}`), 2)
        }
        this.form.totalAmount = tmpAmount
      },
      // 获取电卡电价列表
      async getElePriceList() {
        let res = await Http.getElePriceList()
        if (res.code == 200) {
          this.elePriceList = res.data
          this.form.takeReturnProjectName = res.data[0].electricPriceName
        }
      },
      dialogShow() {
        this.dialogVisible = true
        let {timeStar:feeBeginDate,timeEnd:feeEndDate}=this.getStartAndEnd()
        this.form.feeBeginDate=feeBeginDate
        console.log(this.form.feeEndDate)
        this.form.feeEndDate=feeEndDate
      },
      getStartAndEnd(){
        let now=new Date()
        var nowMonth = now.getMonth(); //当前月
        var nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        //本月的结束时间
        var monthEndDate = new Date(nowYear, nowMonth+1, 0);
        var timeStar=dateFormat.formatDate(monthStartDate, 'yyyy-MM-dd');
        var timeEnd=dateFormat.formatDate(monthEndDate, 'yyyy-MM-dd');
        return {timeStar,timeEnd}
      },
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel()
        }
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      cancelDialog() {
        this.dialogVisible = false
        this.editDialogVisible = false
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        }
        this.form = { //费用录入传值
          areaName: '',
          placeName: '',
          boothCode: '',
          idCard: '',
          inDate: '',
          signPerson: '',
          phone: '',
          areas: '',
          contractTypeCn: '',
          expireDate: '',
          areaId: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: 3,
          isConfirm: 2,
          orderId: '',
          placeId: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: ''
        }
        this.boothId = ''

        // this.$refs.form.resetFields();
      },
      showDialog(row) {
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        }
        this.dialogVisible = true
      },
      async getData() {
        let res = await Http.electricExpenseEntryList(this.searchForm)
        if (res.code == 200) {
          let data = res.data
          this.tableData = data.page.records
          this.count = data.page.total
          this.total = {
            feeNum: data.feeNum,
            totalAmount: data.totalAmount,
            electricPriceTotal: data.electricPriceTotal,
            electricServicePriceTotal: data.electricServicePriceTotal
          }
        }
      },
      remove(id) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.expenseEntryDelete(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async expenseEntryDelete(id) {
        let res = await Http.expenseEntryDelete({id})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
          status: null
        })
        if (res.code == 200) {
          this.formColumns[6].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null
        })
        if (res.code == 200) {
          this.formColumns[3].options = res.data
          this.areaOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }

  .title {
    font-weight: bold;
    padding-left: 10px;
    border-left: 2px solid #4d68ff;
    margin-bottom: 20px;
  }
</style>
